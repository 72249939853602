// @ts-nocheck
import { twJoin } from "tailwind-merge";
import { CancelRounded, DomainDisabledRounded, WarningRounded } from "@mui/icons-material";
import Alert from "./Alert";
import SanctionsWatchlistCard from "./SanctionsWatchlistCard";
import FindingsLoadingIndicator from "./FindingsLoadingIndicator";

const SanctionsWatchlistFindings = ({ checkResult, hideHeader }) => {
  if (!["complete", "error"].includes(checkResult.status)) {
    return (
      <FindingsLoadingIndicator
        message={`Running Sanctions Watchlist check${
          checkResult.metadata?.business_name ? ` on ${checkResult.metadata.business_name} ` : ""
        }...`}
      />
    );
  }

  if (!checkResult.check_result) {
    return null;
  }

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
        {checkResult.check_result?.verified_sanctions_hits?.map((sanctionHit, index) => (
          <SanctionsWatchlistCard
            key={index}
            countryCode={sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0].split("_")[0] : null}
            url={sanctionHit.source_url}
            sanctionListName={sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0] : null}
            matchedNames={sanctionHit.matched_names}
            level="failure"
            description={sanctionHit.description}
          />
        ))}
      </div>
    </div>
  );
};

export default SanctionsWatchlistFindings;
