// @ts-nocheck
import DetailValue from "@/components/TailwindComponents/DetailValue";
import BusinessHeader from "./BusinessHeader";
import BusinessProfileSkeletons from "./BusinessProfileSkeletons";
import SourceTagCircle from "@/components/TailwindComponents/SourceTagCircle";
import {
  AttachMoneyRounded,
  EmailRounded,
  FactoryRounded,
  LocationOnRounded,
  PeopleAltRounded,
  PhoneRounded,
} from "@mui/icons-material";

const BusinessBasicProfile = ({
  checkResult,
  sourcesDictionary,
  setActiveSource,
  activeSource,
  input,
  handleSourceClick,
}: {
  checkResult: {
    verified_data?: {
      business_name_found?: { business_name: string };
      business_industry?: any;
      business_industry_found?: any;
      business_locations?: any[];
      business_locations_found?: any[];
      contact_info?: any;
      contact_info_found?: any;
      backed_by?: any;
      backed_by_found?: any;
      investment_received?: any;
      investment_received_found?: any;
      estimated_range_num_employees?: any;
      estimated_range_num_employees_found?: any;
    };
  };
  sourcesDictionary: { [key: string]: any };
  setActiveSource: (sourceId: number) => void;
  activeSource: number | null;
  input: {
    self_attested_data: {
      business_name: string;
      website?: string;
    };
  };
  handleSourceClick: (sourceId: string) => void;
}) => {
  if (!checkResult?.verified_data) {
    return (
      <div className="flex flex-col gap-y-5 rounded-lg min-w-full md:min-w-0 md:w-[440px] p-5 bg-[#F8FAFC] h-fit">
        <BusinessHeader
          name={input.self_attested_data.business_name}
          website={input.self_attested_data.website ?? null}
        />
        <BusinessProfileSkeletons />
      </div>
    );
  }

  const bizNameFound = checkResult.verified_data.business_name_found;
  const bizIndustryFound =
    checkResult.verified_data.business_industry || checkResult.verified_data.business_industry_found;
  const bizLocationsFound =
    checkResult.verified_data.business_locations || checkResult.verified_data.business_locations_found;

  const bizContactInfoFound = checkResult.verified_data.contact_info || checkResult.verified_data.contact_info_found;
  const bizBackedByFound = checkResult.verified_data.backed_by || checkResult.verified_data.backed_by_found;
  const bizInvestmentReceivedFound =
    checkResult.verified_data.investment_received || checkResult.verified_data.investment_received_found;
  const bizEstimatedNumEmployeesFound =
    checkResult.verified_data.estimated_range_num_employees ||
    checkResult.verified_data.estimated_range_num_employees_found;

  return (
    <div className="flex flex-col gap-y-5 rounded-lg bg-slate-50 p-5 w-full mx-auto lg:mx-0 h-fit min-w-0">
      <BusinessHeader
        name={bizNameFound?.business_name || input.self_attested_data.business_name}
        website={input.self_attested_data.website ?? null}
      />
      {checkResult?.status === "complete" ? (
        <div className="flex flex-col gap-y-5 text-sm">
          <div className="flex flex-col gap-y-2 h-full">
            {bizLocationsFound && bizLocationsFound.length > 0 ? (
              bizLocationsFound.map((location, index) => (
                <div className="flex items-baseline gap-x-2" key={index}>
                  <DetailValue
                    value={`${location?.address?.city ? `${location.address.city}, ` : ""}${
                      location.address?.state ? `${location.address.state}, ` : ""
                    }${location.address?.country_code ?? ""} ${location.address?.postal_code ?? ""}`}
                    icon={<LocationOnRounded sx={{ fontSize: "1rem" }} />}
                  />
                  <SourceTagCircle
                    source={sourcesDictionary && sourcesDictionary[location.source_id]}
                    isActive={activeSource === location.source_id}
                    setActiveSource={setActiveSource}
                    handleSourceClick={handleSourceClick}
                  />
                </div>
              ))
            ) : (
              <div className="flex items-baseline gap-x-2">
                <DetailValue value="Unknown" icon={<LocationOnRounded sx={{ fontSize: "1rem" }} />} />
              </div>
            )}
            <div className="flex items-baseline gap-x-2">
              <DetailValue
                value={bizIndustryFound?.industry ?? "Unknown"}
                icon={<FactoryRounded sx={{ fontSize: "1rem" }} />}
              />
              <SourceTagCircle
                source={sourcesDictionary && sourcesDictionary[bizIndustryFound?.source_id]}
                isActive={activeSource === bizIndustryFound?.source_id}
                setActiveSource={setActiveSource}
                handleSourceClick={handleSourceClick}
              />
            </div>

            <div className="flex gap-x-2">
              <DetailValue
                value={
                  !bizBackedByFound?.backed_by && !bizInvestmentReceivedFound?.investment_received ? (
                    "Unknown"
                  ) : (
                    <span>
                      {bizBackedByFound?.backed_by ?? ""}
                      {bizInvestmentReceivedFound?.investment_received
                        ? `${
                            bizBackedByFound?.backed_by ? ", " : ""
                          }${bizInvestmentReceivedFound?.investment_received} raised`
                        : ""}
                    </span>
                  )
                }
                icon={<AttachMoneyRounded sx={{ fontSize: "1rem" }} />}
              />
              <SourceTagCircle
                source={sourcesDictionary && sourcesDictionary[bizBackedByFound?.source_id]}
                isActive={activeSource === bizBackedByFound?.source_id}
                setActiveSource={setActiveSource}
                handleSourceClick={handleSourceClick}
              />
              {(!bizBackedByFound?.source_id ||
                bizBackedByFound?.source_id !== bizInvestmentReceivedFound?.source_id) && (
                <SourceTagCircle
                  source={sourcesDictionary && sourcesDictionary[bizInvestmentReceivedFound?.source_id]}
                  isActive={activeSource === bizInvestmentReceivedFound?.source_id}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              )}
            </div>

            <div className="flex items-baseline gap-x-2">
              <DetailValue
                value={bizEstimatedNumEmployeesFound?.estimated_num_employees ?? "Unknown"}
                icon={<PeopleAltRounded sx={{ fontSize: "1rem" }} />}
              />
              {bizEstimatedNumEmployeesFound && (
                <SourceTagCircle
                  source={sourcesDictionary && sourcesDictionary[bizEstimatedNumEmployeesFound.source_id]}
                  isActive={activeSource === bizEstimatedNumEmployeesFound.source_id}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              )}
            </div>

            <div className="flex items-baseline gap-x-2">
              <DetailValue
                value={bizContactInfoFound?.phone?.phone ?? "Unknown"}
                icon={<PhoneRounded sx={{ fontSize: "1rem" }} />}
              />
              {bizContactInfoFound?.phone && (
                <SourceTagCircle
                  source={sourcesDictionary && sourcesDictionary[bizContactInfoFound.phone.source_id]}
                  isActive={activeSource === bizContactInfoFound.phone.source_id}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              )}
            </div>

            <div className="flex items-baseline gap-x-2">
              <DetailValue
                value={bizContactInfoFound?.email?.email ?? "Unknown"}
                icon={<EmailRounded sx={{ fontSize: "1rem" }} />}
              />
              {bizContactInfoFound?.email && (
                <SourceTagCircle
                  source={sourcesDictionary && sourcesDictionary[bizContactInfoFound.email.source_id]}
                  isActive={activeSource === bizContactInfoFound.email.source_id}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <BusinessProfileSkeletons />
      )}
    </div>
  );
};

export default BusinessBasicProfile;
