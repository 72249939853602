// @ts-nocheck
import { Fragment, useState } from "react";
import { twJoin } from "tailwind-merge";
import {
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  CalendarTodayRounded,
  LocationOnRounded,
  PersonRounded,
} from "@mui/icons-material";
import SourceItem from "./SourceItem";
import StrengthIndicator from "./MiniStrengthIndicator";
import MiniMatchLevelPill from "./MiniMatchLevelPill";
import { TopicChip } from "./AdverseMediaV2";
import Alert from "./Alert";

const TabularAdverseMedia = ({
  verifiedAdverseMediaHits,
  input,
}: {
  verifiedAdverseMediaHits: any;
  input: {
    first_name: string;
    last_name: string;
    middle_name: string | null;
    country_of_residence?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    country_of_nationality?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    address_country?:
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string;
    date_of_birth: string;
    individual_age: number;
    today_date: string;
  };
}) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleExpandCollapse = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="border border-solid border-slate-200 rounded-lg shadow-xs min-w-full text-xs overflow-x-auto">
      <div className="flex items-center gap-x-5 px-10 py-5 text-sm border-b border-b-slate-200">
        <div className="flex gap-x-2 items-center">
          <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
          <span className="capitalize font-semibold">
            {input.first_name}
            {input.last_name ? ` ${input.last_name}` : null}
          </span>
        </div>
        <div className="flex gap-x-2 items-center">
          <CalendarTodayRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
          <span className="font-semibold">
            {input.individual_age ? `${input.individual_age} yrs old today` : "Not Available"}
          </span>
        </div>
        <div className="flex gap-x-2 items-center">
          <LocationOnRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
          <span className="capitalize font-semibold">
            {input.country_of_residence?.country_name ??
              input.address_country?.country_name ??
              input.country_of_nationality?.country_name ??
              "Unknown"}
          </span>
        </div>
      </div>
      <table className="w-0 min-w-full divide-y divide-slate-200">
        <thead>
          {verifiedAdverseMediaHits.length > 0 && (
            <tr className="bg-slate-50">
              <th className="px-2 py-3 text-left text-slate-500">Strength</th>
              <th className="px-2 py-3 text-left text-slate-500">First</th>
              <th className="px-2 py-3 text-left text-slate-500">Last</th>
              <th className="min-w-[9rem] px-2 py-3 text-left text-slate-500">
                <span>Age Today</span>
              </th>
              <th className="px-2 py-3 text-left text-slate-500">
                <span>Location</span>
              </th>
              <th className="px-2 py-3 text-left text-slate-500">Adverse Media</th>
            </tr>
          )}
        </thead>
        <tbody className="divide-y divide-slate-200">
          {verifiedAdverseMediaHits.map((profile, index) => {
            const review = profile.profile_review || profile.perpetrator_review;
            const matchRating = review?.match_rating?.match;
            const countryMatch = review?.country_match;
            const firstNameMatch = review?.first_name_match;
            const middleNameMatch = review?.middle_name_match;
            const lastNameMatch = review?.last_name_match;
            const ageMatch = review?.age_match;
            const event = profile;
            const isDiscounted = matchRating === "no_match";

            return (
              <Fragment key={profile.id}>
                <tr
                  onClick={() => handleExpandCollapse(index)}
                  className={twJoin(
                    "cursor-pointer hover:bg-slate-50",
                    isDiscounted ? "bg-slate-50 hover:bg-slate-100" : "",
                  )}
                >
                  <td className="px-2 py-4">
                    <StrengthIndicator matchRating={matchRating} hideLabel={true} />
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex gap-x-2.5 items-center">
                      <span
                        className={twJoin("truncate max-w-[8rem]", isDiscounted ? "text-slate-500" : "text-slate-700")}
                      >
                        {firstNameMatch.display_name ? (
                          <>
                            {firstNameMatch.display_name
                              .split(new RegExp(`(${firstNameMatch.name}|${middleNameMatch?.name})`, "i"))
                              .map((part: string, index: number) => (
                                <span
                                  key={index}
                                  className={twJoin(
                                    "capitalize",
                                    part?.toLowerCase() === firstNameMatch?.name?.toLowerCase() ||
                                      part?.toLowerCase() === middleNameMatch?.name?.toLowerCase()
                                      ? "font-semibold"
                                      : "",
                                    isDiscounted
                                      ? "text-slate-500"
                                      : firstNameMatch.exact_match || middleNameMatch?.exact_match
                                        ? "text-slate-900"
                                        : firstNameMatch.partial_match ||
                                            middleNameMatch?.partial_match ||
                                            (!firstNameMatch.name && !middleNameMatch?.name)
                                          ? "text-slate-500"
                                          : "text-slate-700",
                                  )}
                                >
                                  {part ? part.toLowerCase() : ""}
                                </span>
                              ))}
                          </>
                        ) : (
                          <span
                            className={twJoin(
                              "truncate capitalize",
                              isDiscounted
                                ? "text-slate-500"
                                : firstNameMatch?.exact_match || middleNameMatch?.exact_match
                                  ? "text-slate-900"
                                  : firstNameMatch?.partial_match || middleNameMatch?.partial_match
                                    ? "text-slate-500"
                                    : "text-slate-700",
                              !firstNameMatch?.name && !middleNameMatch?.name ? "italic" : "",
                              firstNameMatch?.exact_match ? "font-semibold" : "",
                            )}
                          >
                            {firstNameMatch.name ?? profile?.forename}
                          </span>
                        )}
                      </span>
                      <MiniMatchLevelPill
                        matchRating={
                          firstNameMatch.exact_match
                            ? "strong_match"
                            : firstNameMatch.partial_match
                              ? "partial_match"
                              : firstNameMatch.mismatch
                                ? "mismatch"
                                : "First Name Not Found"
                        }
                      />
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex gap-x-2.5 items-center">
                      <span className="truncate max-w-[8rem]">
                        {lastNameMatch.display_name ? (
                          <>
                            {lastNameMatch.display_name
                              .split(new RegExp(`(${lastNameMatch.name}|${middleNameMatch.name})`, "i"))
                              .map((part, index) => (
                                <span
                                  key={index}
                                  className={twJoin(
                                    part?.toLowerCase() === lastNameMatch?.name?.toLowerCase() ? "font-semibold" : "",
                                    isDiscounted || lastNameMatch.partial_match || !lastNameMatch.name
                                      ? "text-slate-500"
                                      : lastNameMatch.exact_match
                                        ? "text-slate-900"
                                        : "text-slate-700",
                                  )}
                                >
                                  {part}
                                </span>
                              ))}
                          </>
                        ) : (
                          <span
                            className={twJoin(
                              "truncate max-w-[8rem]",
                              isDiscounted || lastNameMatch.partial_match || !lastNameMatch.name
                                ? "text-slate-500"
                                : lastNameMatch.exact_match
                                  ? "text-slate-900"
                                  : "text-slate-700",
                              lastNameMatch.exact_match ? "font-semibold" : "",
                            )}
                          >
                            {lastNameMatch.name ? lastNameMatch.name : profile.surname}
                          </span>
                        )}
                      </span>
                      <MiniMatchLevelPill
                        matchRating={
                          lastNameMatch.exact_match
                            ? "strong_match"
                            : lastNameMatch.partial_match
                              ? "partial_match"
                              : lastNameMatch.mismatch
                                ? "mismatch"
                                : "Last Name Not Found"
                        }
                      />
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex gap-x-2.5 items-center">
                      {(ageMatch.age || ageMatch.min_age || ageMatch.max_age) && (
                        <div className="flex items-center gap-x-1">
                          {(ageMatch.min_age || ageMatch.max_age) && (
                            <AutoAwesomeRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
                          )}
                          <span
                            className={twJoin(
                              ageMatch.exact_match ? "font-semibold" : "",
                              isDiscounted || ageMatch.partial_match || !ageMatch.age
                                ? "text-slate-500"
                                : ageMatch.exact_match
                                  ? "text-slate-900"
                                  : "text-slate-700",
                              !ageMatch.age ? "italic" : "",
                            )}
                          >
                            {ageMatch?.age
                              ? `${ageMatch?.age} yrs`
                              : ageMatch?.min_age && ageMatch?.max_age
                                ? `${ageMatch.min_age} - ${ageMatch.max_age}`
                                : ageMatch?.min_age
                                  ? `${ageMatch.min_age} yrs or older`
                                  : ageMatch?.max_age
                                    ? `${ageMatch.max_age} yrs or younger`
                                    : null}
                          </span>
                        </div>
                      )}
                      <MiniMatchLevelPill
                        matchRating={
                          ageMatch.exact_match
                            ? "strong_match"
                            : ageMatch.partial_match
                              ? "partial_match"
                              : ageMatch.mismatch
                                ? "mismatch"
                                : "Age Not Found"
                        }
                      />
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="flex gap-x-2.5 items-center">
                      {countryMatch?.countries?.length > 0 && (
                        <div className="w-fit max-w-[10rem]">
                          {countryMatch.countries.map((country, index) => {
                            return (
                              <span
                                key={country.country_name}
                                className={twJoin(
                                  "block text-left",
                                  [
                                    input.country_of_residence?.country_name ??
                                      input.address_country?.country_name ??
                                      input.country_of_nationality?.country_name,
                                  ].includes(country.country_name ? country.country_name : country)
                                    ? "font-semibold"
                                    : "",
                                  isDiscounted
                                    ? "text-slate-500"
                                    : countryMatch.exact_match
                                      ? "text-slate-900"
                                      : countryMatch.partial_match
                                        ? "text-slate-500"
                                        : "text-slate-700",
                                )}
                              >
                                {country.country_name
                                  ? index < countryMatch.countries.length - 1
                                    ? `${country.country_name}, `
                                    : country.country_name
                                  : country}
                              </span>
                            );
                          })}
                        </div>
                      )}
                      <MiniMatchLevelPill
                        matchRating={
                          countryMatch.exact_match
                            ? "strong_match"
                            : countryMatch.partial_match
                              ? "partial_match"
                              : countryMatch.mismatch
                                ? "mismatch"
                                : "Country Not Found"
                        }
                      />
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="w-full flex flex-col gap-y-2">
                      <div className="flex">
                        {event?.title && (
                          <span className={twJoin(isDiscounted ? "text-slate-500" : "text-slate-900")}>
                            {event.title}
                          </span>
                        )}
                      </div>

                      {/** Here goes the list of Topics  */}
                      {event.topics?.length ? (
                        <div className="flex flex-wrap items-center gap-2 text-slate-500">
                          {event.topics.map((topic, index) => (
                            <TopicChip
                              key={index}
                              topic={topic}
                              className={twJoin(isDiscounted ? "text-slate-500" : "")}
                            />
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </td>
                </tr>
                {expandedIndex === index ? (
                  <tr>
                    <td colSpan={7} className="w-0 min-w-full p-4">
                      <EventCard profile={profile} input={input} />
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const EventCard = ({ profile, input }: { profile: any; input: any }) => {
  const [showAllArticles, setShowAllArticles] = useState(false);

  const event = profile;
  const review = profile.profile_review || profile.perpetrator_review;
  const match = review.match_rating.match;
  const reason = review.match_rating.reason;

  return (
    <div className="w-0 min-w-full flex flex-col gap-y-4">
      {match && reason && (
        <Alert
          level={match === "strong_match" ? "failure" : match === "partial_match" ? "partial" : "caution"}
          message={reason}
        />
      )}
      <div className="flex flex-col gap-y-1">
        {event?.title && <span className="text-sm font-medium">{event.title}</span>}
        {event?.summary && <p className="text-slate-700">{event.summary}</p>}
      </div>

      {/** Here goes the list of Topics  */}
      {event.topics?.length ? (
        <div className="flex flex-wrap items-center gap-2 text-slate-900 font-medium">
          <span>Topics</span>
          {event.topics.map((topic, index) => (
            <TopicChip key={index} topic={topic} />
          ))}
        </div>
      ) : null}

      <div className="grid grid-cols-2 gap-x-4">
        {event.when && (
          <div className="flex flex-col gap-2">
            <span className="font-medium">Year of Event</span>
            <span className="text-slate-700">{event.when}</span>
          </div>
        )}
        {event.associated_countries?.length > 0 && (
          <div className="flex flex-col gap-2">
            <span className="font-medium">Related Locations</span>
            <span className="text-slate-700">
              {event.associated_countries
                .map((country) =>
                  country !== null && typeof country === "object" && "country_name" in country
                    ? country.country_name
                    : country,
                )
                .join(", ")}
            </span>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-y-2">
        {showAllArticles
          ? event?.weblinks?.map((weblink) => (
              <SourceItem
                key={weblink.id}
                url={weblink.url}
                title={weblink.scanned_website?.webpage_title || weblink.metadata?.adverse_media_article_title}
                sourceName={weblink.scanned_website?.source_name}
                summary={
                  weblink.summary ||
                  weblink.metadata?.summary_of_relation_to_crime ||
                  weblink.metadata?.summary_of_event
                }
                text={weblink.scanned_website?.webpage_text}
                hasPhoto={weblink.has_photo}
                wasRemoved={false}
                who={weblink.metadata?.full_name}
                when={weblink.metadata?.year_of_the_crime}
                what={weblink.metadata?.topics}
                where={weblink.metadata?.associated_countries}
                perpetratorAge={weblink.metadata?.age}
              />
            ))
          : null}
      </div>

      {event?.weblinks?.length > 0 && (
        <button
          type="button"
          disabled={!event?.weblinks?.length}
          className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300"
          onClick={() => setShowAllArticles(!showAllArticles)}
        >
          {/** Here we need to calculate the number of articles related to this Event */}
          {showAllArticles ? (
            <>
              {`Hide ${event?.weblinks?.length ?? 0} Article${event?.weblinks?.length === 1 ? "" : "s"}`}
              <ArrowUpwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </>
          ) : (
            <>
              <span>{`Show ${event?.weblinks?.length ?? 0} Article${event?.weblinks?.length === 1 ? "" : "s"}`}</span>
              <ArrowDownwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default TabularAdverseMedia;
