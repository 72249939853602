// @ts-nocheck
import BusinessBasicProfile from "@/components/TailwindComponents/BusinessBasicProfile";
import WebPresenceList from "@/components/TailwindComponents/WebPresence";
import HighRiskCountryFindings from "@/components/TailwindComponents/HighRiskCountryFindings";
import HighRiskIndustryFindings from "@/components/TailwindComponents/HighRiskIndustryFindings";
import AdverseMediaFindings from "@/components/TailwindComponents/AdverseMediaFindings";
import { VerifiedUserRounded } from "@mui/icons-material";
import SanctionsWatchlistFindings from "@/components/TailwindComponents/SanctionsWatchlistFindings";

const BUSINESS_PROFILE_CHECK_ID = "kyb.basic_business_profile_check";
const WEB_PRESENCE_CHECK_ID = "kyb.web_presence_check";
const HIGH_RISK_COUNTRY_CHECK_ID = "kyb.high_risk_country_tool";
const HIGH_RISK_INDUSTRY_CHECK_ID = "kyb.high_risk_industry_tool";
const ADVERSE_MEDIA_CHECK_ID = "kyb.screening.adverse_media_tool";
const SANCTIONS_WATCHLIST_CHECK_ID = "kyb.sanctions_watchlist_check";

const AssociatedEntityFindings = ({
  checkResults,
  onTryAgainClick,
}: {
  checkResults: any;
  onTryAgainClick: () => void;
}) => {
  const businessProfileCheckResult = checkResults?.find(
    (result: any) => result.command_id === BUSINESS_PROFILE_CHECK_ID,
  );
  const webPresenceCheckResult = checkResults?.find((result: any) => result.command_id === WEB_PRESENCE_CHECK_ID);
  const highRiskCountryCheckResult = checkResults?.find(
    (result: any) => result.command_id === HIGH_RISK_COUNTRY_CHECK_ID,
  );
  const highRiskIndustryCheckResult = checkResults?.find(
    (result: any) => result.command_id === HIGH_RISK_INDUSTRY_CHECK_ID,
  );
  const adverseMediaCheckResult = checkResults?.find((result: any) => result.command_id === ADVERSE_MEDIA_CHECK_ID);
  const sanctionsWatchlistCheckResult = checkResults?.find(
    (result: any) => result.command_id === SANCTIONS_WATCHLIST_CHECK_ID,
  );

  const sites =
    (webPresenceCheckResult?.verified_data?.self_attested_webpages?.length > 0
      ? webPresenceCheckResult?.verified_data?.self_attested_webpages
      : webPresenceCheckResult?.verified_data?.google_search_results) || [];
  let officialBusinessWebsite =
    sites?.find(
      (website: any) =>
        website.webpage_metadata?.webpage_type === "official_business_website" ||
        website.analysis?.extracted_metadata?.webpage_type === "official_business_website",
    ) || webPresenceCheckResult?.verified_data?.self_attested_webpages?.[0];

  if (officialBusinessWebsite) {
    officialBusinessWebsite["title"] =
      officialBusinessWebsite.webpage_title || officialBusinessWebsite.scanned_website?.webpage_title;
    officialBusinessWebsite["visual_summary"] =
      officialBusinessWebsite.webpage_metadata?.visual_summary ||
      officialBusinessWebsite.analysis?.extracted_metadata?.visual_summary ||
      officialBusinessWebsite.webpage_metadata?.summary ||
      officialBusinessWebsite.scanned_website?.webpage_metadata?.summary;
    officialBusinessWebsite["url"] =
      officialBusinessWebsite.webpage_url || officialBusinessWebsite.scanned_website?.webpage_url;
  } else {
    officialBusinessWebsite =
      webPresenceCheckResult?.check_result?.official_website_matches ||
      webPresenceCheckResult?.check_result?.official_website_match;
  }

  if (
    officialBusinessWebsite &&
    !officialBusinessWebsite.screenshot_url &&
    !officialBusinessWebsite.scanned_website?.screenshot_url
  ) {
    // use first self attested result (backwards compatibility)
    officialBusinessWebsite.screenshot_url =
      webPresenceCheckResult?.verified_data?.self_attested_webpages?.find(
        (item: any) =>
          item.webpage_url === officialBusinessWebsite?.url ||
          item.scanned_website?.webpage_url === officialBusinessWebsite?.url,
      )?.screenshot_url ||
      webPresenceCheckResult?.verified_data?.self_attested_webpages?.find(
        (item: any) =>
          item.webpage_url === officialBusinessWebsite?.url ||
          item.scanned_website?.webpage_url === officialBusinessWebsite?.url,
      )?.webpage_structured_scrape?.profilePicUrlHD;
  }

  return (
    <div className="flex justify-center align-center h-full w-full">
      <div className="flex flex-col gap-y-5 w-full">
        {businessProfileCheckResult && (
          <BusinessBasicProfile
            checkResult={businessProfileCheckResult}
            officialBusinessWebsite={
              officialBusinessWebsite && {
                webpage_url: officialBusinessWebsite.url || officialBusinessWebsite.scanned_website?.url,
                screenshot_url:
                  officialBusinessWebsite.screenshot_url || officialBusinessWebsite.scanned_website?.screenshot_url,
                title: officialBusinessWebsite.title || officialBusinessWebsite.scanned_website?.title,
                visual_summary:
                  officialBusinessWebsite.visual_summary || officialBusinessWebsite.scanned_website?.visual_summary,
              }
            }
            onTryAgainClick={onTryAgainClick}
          />
        )}

        {webPresenceCheckResult && <WebPresenceList checkResult={webPresenceCheckResult} />}

        {(highRiskCountryCheckResult || highRiskIndustryCheckResult) && (
          <div className="flex-1 flex gap-x-5 justify-between">
            {highRiskCountryCheckResult && (
              <div className="flex-1">
                <HighRiskCountryFindings checkResult={highRiskCountryCheckResult} hideHeader={true} />
              </div>
            )}
            {highRiskIndustryCheckResult && (
              <div className="flex-1">
                <HighRiskIndustryFindings checkResult={highRiskIndustryCheckResult} hideHeader={true} />
              </div>
            )}
          </div>
        )}

        {(adverseMediaCheckResult || sanctionsWatchlistCheckResult) && (
          <div className="flex-1 flex flex-col gap-y-3">
            <div className="flex items-center gap-x-2">
              <VerifiedUserRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
              <span className="text-lg font-semibold">Business Screenings</span>
            </div>
            <div className="grid grid-cols-2 gap-3">
              {adverseMediaCheckResult && (
                <div className="flex-1">
                  <AdverseMediaFindings checkResult={adverseMediaCheckResult} hideHeader={true} />
                </div>
              )}
              {sanctionsWatchlistCheckResult && (
                <div className="flex-1">
                  <SanctionsWatchlistFindings checkResult={sanctionsWatchlistCheckResult} hideHeader={true} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AssociatedEntityFindings;
